<template>

  <div class="section success">
    <img
      v-lazy="`$/images/auth_user_joined.svg`"
      alt=""
      class="image success__image"
    >

    <h1 class="thank has-text-weight-black">
      Thank you for joining
      <span class="has-text-primary">Pouch!</span>
    </h1>

    <div
      class="
        has-text-grey
        has-text-weight-medium
      "
    >
      A verification was sent to your email address. Please check your mailbox to verify your email address before you sign in.
    </div>
    <form @submit.prevent="resendEmail">
      <p-button
        :disabled="buttonStatus"
        primary
        class="button"
        type="submit"
      >
        Resend Email
      </p-button>
    </form>

    <div
      class="
        has-text-grey
        has-text-weight-medium
      "
    >
      Still didn't get the email?
    </div>

    <div
      class="
        has-text-grey
        has-text-weight-medium
      "
    >
      Please contact
      <a
        href="mailto:membership@joinpouch.com"
        class="has-text-link"
      >
        membership@joinpouch.com
      </a>
    </div>

  </div>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      buttonStatus: false
    }
  },
  mounted () {
    this.$emit('loading', false)
  },

  methods: {
    ...mapActions('account/', ['resendRegister']),

    async resendEmail () {
      this.$emit('loading', true)
      this.buttonStatus = !this.buttonStatus

      await this.resendRegister({
        credentials: {
          email: atob(this.$route.query.jce)
        }
      })
        .then(() => atob(this.email)) // Prevents errors if atob return an exception.
        .then(() => {
          this.buttonStatus = !this.buttonStatus
          this.$messages.success('The email has been resent!', '', true)
          return this.$router.push({ name: 'join-complete' })
        })
        .catch(e => {
          this.buttonStatus = !this.buttonStatus
          this.submissionError = e.message
        })

      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="sass" scoped>
.success
  max-width: 470px
  margin: 100px auto
  text-align: center
  &__image
    margin: 0 auto

.thank
  font-size: 27px
div
  margin-top: 16px
  font-size: 14px

button
  margin: 24px 0 12px
</style>
